<template>
  <ul class="kt-menu__nav">
    <template v-for="(menu, i) in menuItems">
      <KTMenuItem v-bind:menu="menu" :key="i" v-if="currentUser.role == menu.role"></KTMenuItem>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";
import {mapGetters} from "vuex";

export default {
  name: "KTHeaderMenu",
  components: {
    KTMenuItem
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ]),
    menuItems: () => {
      return menuConfig.header.items;
    }
  }
};
</script>
