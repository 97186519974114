<template>
  <div class="kt-subheader kt-grid__item" id="kt_subheader">
    <div
      class="kt-container"
      v-bind:class="{ 'kt-container--fluid': widthFluid }"
    >
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">
          {{ title }}
        </h3>
        <div class="kt-subheader__breadcrumbs">
          <router-link :to="'/'" class="kt-subheader__breadcrumbs-home"
            ><i class="flaticon2-shelter"></i
          ></router-link>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <span
              :key="`${i}-${breadcrumb.id}`"
              class="kt-subheader__breadcrumbs-separator"
            ></span>
            <router-link
              v-if="breadcrumb.route"
              :key="i"
              :to="breadcrumb.route"
              class="kt-subheader__breadcrumbs-link"
            >
              {{ breadcrumb.title }}
            </router-link>
            <span
              class="kt-subheader__desc"
              :key="i"
              v-if="!breadcrumb.route"
              >{{ breadcrumb.title }}</span
            >
          </template>
        </div>
      </div>
      <div class="kt-subheader__toolbar">
        <div class="kt-subheader__wrapper">
          <a href="#" class="btn kt-subheader__btn-primary">
            Actions &nbsp;
            <!--<i class="flaticon2-calendar-1"></i>-->
          </a>

          <div
            class="dropdown dropdown-inline"
            v-b-tooltip.hover.left="'Quick actions'"
          >
            <a
              href="#"
              class="btn btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--md'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5.857 2h7.88a1.5 1.5 0 01.968.355l4.764 4.029A1.5 1.5 0 0120 7.529v12.554c0 1.79-.02 1.917-1.857 1.917H5.857C4.02 22 4 21.874 4 20.083V3.917C4 2.127 4.02 2 5.857 2z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 14H9a1 1 0 010-2h2v-2a1 1 0 012 0v2h2a1 1 0 010 2h-2v2a1 1 0 01-2 0v-2z" fill="#000"/></g></svg>
              <!--<i class="flaticon2-plus"></i>-->
            </a>
            <div
              class="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right"
            >
              <!--begin::Nav-->
              <ul class="kt-nav">
                <li class="kt-nav__head">
                  Add anything or jump to:
                  <i
                    class="flaticon2-information"
                    v-b-tooltip.hover.bottom="'Click to learn more...'"
                  ></i>
                </li>
                <li class="kt-nav__separator"></li>
                <li class="kt-nav__item">
                  <a href="#" class="kt-nav__link">
                    <i class="kt-nav__link-icon flaticon2-drop"></i>
                    <span class="kt-nav__link-text">Order</span>
                  </a>
                </li>
                <li class="kt-nav__item">
                  <a href="#" class="kt-nav__link">
                    <i class="kt-nav__link-icon flaticon2-calendar-8"></i>
                    <span class="kt-nav__link-text">Ticket</span>
                  </a>
                </li>
                <li class="kt-nav__item">
                  <a href="#" class="kt-nav__link">
                    <i class="kt-nav__link-icon flaticon2-telegram-logo"></i>
                    <span class="kt-nav__link-text">Goal</span>
                  </a>
                </li>
                <li class="kt-nav__item">
                  <a href="#" class="kt-nav__link">
                    <i class="kt-nav__link-icon flaticon2-new-email"></i>
                    <span class="kt-nav__link-text">Support Case</span>
                    <span class="kt-nav__link-badge">
                      <span class="kt-badge kt-badge--success">5</span>
                    </span>
                  </a>
                </li>
                <li class="kt-nav__separator"></li>
                <li class="kt-nav__foot">
                  <a class="btn btn-label-brand btn-bold btn-sm" href="#"
                    >Upgrade plan</a
                  >
                  <a
                    class="btn btn-clean btn-bold btn-sm"
                    href="#"
                    v-b-tooltip.hover.bottom="'Click to learn more...'"
                    >Learn more</a
                  >
                </li>
              </ul>
              <!--end::Nav-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    }
  }
};
</script>
